import './App.css'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blue } from '@mui/material/colors'
import LogoutIcon from '@mui/icons-material/Logout'
import LoanAndCash from './components/LoanAndCash/LoanAndCash'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from './components/Select'
import Box from '@mui/material/Box'
import AuthenticationModal from './components/AuthenticationModal'
import LeaderShipLevel from './components/LeaderShipLevel'
import RepTenure from './components/RepTenure'
import { useCookies } from 'react-cookie'
import AdminPortal from './components/Admin/AdminPortal'
import { TextField, InputAdornment} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DisclosureModal from './components/DisclosureModal'

function App() {
  const [market, setMarket] = useState('')
  const [installer, setInstaller] = useState('')
  const [installerValues, setInstallerValues] = useState([])
  const [contractSignDate, setContractSignDate] = useState(new Date())
  const [installerBaseline, setInstallerBaseline] = useState(0)
  const [paymentCalculationsToDisplay, setPaymentCalculationsToDisplay] = useState([])
  const [paymentCalculations, setPaymentCalculations] = useState([])
  const [repTenureValues, setRepTenureValues] = useState([])
  const [repTenure, setRepTenure] = useState()
  const [ppa, setppa] = useState(false)
  const [GSFormValues, setGSFormValues] = useState({marketValues: [], installerValues: []})
  const [netPricePerWatt, setNetPricePerWatt] = useState(3.60)
  const [systemSizeKW, setSystemSizeKW] = useState('')
  const [lead, setLead] = useState('setter')
  const [closer, setCloser] = useState(false)
  const [deductions, setDeductions] = useState(0)
  const [netPricePerWattValues, setNetPricePerWattValues] = useState([])
  const [tcrVariables, setTcrVariables] = useState({})
  const [estimatedInstalls, setEstimatedInstalls] = useState(1)
  const [selectedPayScale, setSelectedPayScale] = useState({totalCommission: 0})
  const [EPCValues, setEPCValues] = useState([])
  const [epcRedline, setEpcRedline] = useState(0)
  const [sunRunMarketValues, setSunRunMarketValues] = useState([])
  const [loanAndCashMarketValues, setLoanAndCashMarketValues] = useState([])
  const [authenticated, setAuthenticated] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [leadershipLevel, setLeadershipLevel] = useState([])
  const [adminRepTenureValues, setAdminRepTenureValues] = useState([])
  const [calculationsSubmitted, setCalculationsSubmitted] = useState(false)
  const [adminPassword, setAdminPassword] = useState()
  const [cookies, setCookie, removeCookie] = useCookies(['authenticate', 'userType', 'password'])
  const [customElements, setCustomElements] = useState([])
  const [tcrPercentage, setTCRPercentage] = useState(50)
  const NEW_CALC_DATE = new Date('4/1/2023')
  const [runCalculations, setRunCalculations] = useState(false)
  const [disclosureOpen, setDisclosureOpen] = useState(true)

  const getVariables = async () => {
    await setPaymentCalculations([])

    let res = await fetch(`/api/get_variables`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userInputs: {
          installer: installer,
          market: market,
          ppa: ppa,
          contractSignDate: contractSignDate
        }
      })
    })
      .then(res => res.json())
      .then(json => {
        setInstallerBaseline(json.A)
        setEpcRedline(json.epc_redline)
        setTcrVariables(json.tcrVariables)

        return {installerBaseline: json.A, tcrVariables: json.tcrVariables}
      })

      return res
  }

  const buildTablePaymentCalculations = async () => {
    if(repTenureValues.length < 1 && leadershipLevel.length < 1 && customElements.length < 1 || !cookies.authenticate || !cookies.password) {
      authenticate()
    } else {
      let A = Number(installerBaseline)
      let C = Number(systemSizeKW)
      let D = Number(deductions)
      let E = Number(tcrVariables.tcrConstant)
      let F = Number(tcrVariables.tcrAdjuster)

      let calculations = netPricePerWattValues.map(B => {
        let paymentCalculation = crunchNumbers(A, B, C, D, E, F, repTenure, lead, installer, epcRedline, contractSignDate)

        return paymentCalculation
      })
      let selection = calculations.filter(c => Number(c.nppw) == Number(netPricePerWatt))[0]

      setPaymentCalculations(calculations)
      setPaymentCalculationsToDisplay(calculations)
      setSelectedPayScale(selection)
      setCalculationsSubmitted(true)
    }
  }

  const crunchNumbers = (A, B, C, D, E, F, repTenure, lead, installer, epc_redline, contractSignDate) => {
    output(['--------------- New Calculation -------------'], B)
    let sumOfLeadership = sumOfSelectedLeadershipValues()
    let salesRepSelected = leadershipLevel.filter(x => x.title == 'Sales Rep' && x.selected).length > 0
    output([`salesRepSelected? ${salesRepSelected}`], B)
    let expensesSelected = leadershipLevel.filter(x => x.title == 'Expenses' && x.selected).length > 0
    output([`expensesSelected? ${expensesSelected}`], B)
    let expensesPercentage = 0.75 - Number(repTenure)
    output([`expensesPercentage ${expensesPercentage}`], B)
    output([`repTenure ${repTenure}`], B)
    output([`sum of leadership values ${sumOfLeadership}`], B)
    let percentagesTotal = Number(repTenure) + Number(sumOfLeadership)
    output([`sum of leadership + repTenure ${percentagesTotal}`], B)
    percentagesTotal = !salesRepSelected && leadershipLevel.filter(x => x.selected).length > 0 ? Number(percentagesTotal) - Number(repTenure) : percentagesTotal
    output([`minus repTenure if sales rep not selected ${percentagesTotal}`], B)
    percentagesTotal = expensesSelected ? Number(percentagesTotal) + Number(expensesPercentage) : percentagesTotal
    output(['plue expenses percentage', percentagesTotal], B)
    output([`NetPricePerWatt ${Number(B)}`], B)
    output([`Baseline ${Number(A)}`], B)
    output([`Deductions ${Number(D)}`], B)
    output([`System Size ${Number(C)}`], B)
    output([`TCR Constant ${Number(E)}`], B)
    output([`TCR Adjuster ${Number(F)}`], B)

    let paymentCalculation = calculatePaymentDynamically(A, B, C, D, E, F, percentagesTotal, installer, epc_redline, contractSignDate)
    output([`paymentCalculation ${paymentCalculation}`], B)

    paymentCalculation = lead !== 'self_gen' ? paymentCalculation / 2 : paymentCalculation
    output(`if lead? paymentCalculation / lead = ${paymentCalculation}`, B)
    output(`leadershipLevel: ${leadershipLevel}`, B)
    const isSalesRepSelected = leadershipLevel.filter(x => x.title === 'Sales Rep' && x.selected === true).length === 1
    output(`Sales Rep Selected: ${isSalesRepSelected}`, B)

    if(installer == '1Solar' && market == 'NV' && new Date(contractSignDate) > new Date('12/31/2023') && lead !== 'setter' && (isSalesRepSelected || leadershipLevel.length === 0)) {
      output(`installer is 1Solar, market is NV, lead type is not setter and the contract sign date is greater than Dec. 31st 2023`, B)
      output(`Sales Rep is also the only leadership level selected.`, B)
      output(`All of this criteria means we will remove the prepaid portion from the payment calculation.`, B)
      paymentCalculation = remove_prepaid_portion(contractSignDate, systemSizeKW, paymentCalculation, B)
      output(`adjustPaymentCalculation after removing prepaid portion ${paymentCalculation}`, B)
    }

    let adjustedPaymentCalculation = adjustFinalPaymentCalculation(paymentCalculation)
    output(`before reducing paymentCalculation by 5% ${paymentCalculation}`, B)
    output(`after reducing paymentCalculation by 5% ${adjustedPaymentCalculation}`, B)

    adjustedPaymentCalculation = round(adjustedPaymentCalculation, 25, 0)
    output(`payment calculation rounded down to multiple of 25: ${adjustedPaymentCalculation}`, B)

    const commissionPerKW = (adjustedPaymentCalculation / C).toFixed(2)
    output(`FINAL commissionPerKW ${commissionPerKW}`, B)

    return {totalCommission: adjustedPaymentCalculation, nppw: B, commissionPerKW: commissionPerKW}
  }

  const remove_prepaid_portion = (contractSignDate, systemSizeKW, adjustedPaymentCalculation, B) => {
    let percentage = new Date(contractSignDate) >= new Date('01/01/2024') && new Date(contractSignDate) < new Date('03/11/2024') ? 0.25 : 0.1
    let removedPortion = (systemSizeKW * 1000) * percentage
    output("****** removed prepaid portion *******", B)
    output(removedPortion, B)

    return adjustedPaymentCalculation - removedPortion
  }

  const round = (number, increment, offset) => {
    return Math.floor((number - offset) / increment ) * increment + offset;
  }

  const output = (outputs, current_nppw) => {
    outputs = Array.isArray(outputs) ? outputs.join(", ") : outputs

    return Number(current_nppw) === Number(netPricePerWatt) ? console.log(outputs) : null
  }

  const adjustFinalPaymentCalculation = (paymentCalculation) => {
    return paymentCalculation * 0.95
  }

  const clearCalculations = async () => {
    const empty = []

    setPaymentCalculations(empty)
    setSelectedPayScale(empty)
    setCalculationsSubmitted(false)
    setInstaller('')
    setMarket('')
    await setPaymentCalculationsToDisplay(empty)
  }

  const calculatePaymentDynamically = (A, B, C, D, E, F, percentagesTotal, installer, epc_redline, contractSignDate) => {
    const freedom_var = installer == 'Freedom Forever' ? 0.8 : 1
    let redlineWbaseline = installer == 'Freedom Forever' ? -0.21 : -0.225
    let tcr = 0
    let commissionAmount = ((Number(B) - epc_redline) * freedom_var) * Number(C) * 1000

    output(['installer:', installer, ', freedom var:', freedom_var], B)
    output(['epcRedline:', epc_redline, ', baseline:', Number(A), ', redlineWbaseline:', redlineWbaseline], B)
    output(["*************** commissionAmount Estimation *******************"], B)
    output([`(((NPPW: ${Number(B)} - EPC_REDLINE: ${Number(epc_redline)})`], B)
    output([`* freedom var: ${freedom_var})`], B)
    output([`* SystemSize: ${Number(C)} * 1000) = commission amount: ${commissionAmount}`], B)
    output(["***************************************************************"], B)

    if (!epc_redline || epc_redline == 0 ) {
      tcr = 0
    } else if (commissionAmount < 0) {
      output("*********** handle negative tcr **************", B)
      tcr = commissionAmount * 0.95
    } else if (commissionAmount < 0 && new Date(contractSignDate) < NEW_CALC_DATE && installer == 'Freedom Forever' ) {
      output("*********** handle negative tcr with old contract sign date for freedom **************", B)
      tcr = commissionAmount * 0.804375
    } else if (commissionAmount < 0 && new Date(contractSignDate) < NEW_CALC_DATE && installer != 'Freedom Forever' ) {
      output("*********** handle negative tcr with old contract sign date nonfreedom **************", B)
      tcr = commissionAmount * 0.79
    } else if (new Date(contractSignDate) >= new Date('07/08/2024') && ppa !== 0) {
      redlineWbaseline = -0.245
      if(installer == '1Solar' && market == 'NV' && new Date(contractSignDate) > new Date('12/31/2023')) {
        redlineWbaseline = -0.18
        let percentage = new Date(contractSignDate) >= new Date('01/01/2024') && new Date(contractSignDate) <= new Date('03/11/2024') ? 0.25 : 0.1
        let prepaidPortion = (systemSizeKW * 1000) * percentage
        commissionAmount = commissionAmount + prepaidPortion

        output(`*** adjusting redline/baseline for 1Solar NV to: ${redlineWbaseline}`, B)
        output("****** prepaid portion that was added to the total commission amount *******", B)
        output(`Prepaid Portion: ${prepaidPortion}, Total Commission Amount with prepaid portion added to it: ${commissionAmount}`, B)
      }

      output("************** New TCR Formula **************************", B)
      output(`(commission amount: ${commissionAmount} + redline with baseline: (${redlineWbaseline}) * SystemSizeKW: ${Number(C)} * 1000)` , B)
      output(`- (0.05 * commission amount ${commissionAmount}) = tcr`, B)
    
      tcr = (commissionAmount + ((redlineWbaseline) * (Number(C) * 1000))) - (0.05 * commissionAmount)
    } else if (new Date(contractSignDate) >= NEW_CALC_DATE && ppa !== 0) {
      if(installer == '1Solar' && market == 'NV') {
        if(new Date(contractSignDate) > new Date('12/31/2023') && new Date(contractSignDate) < new Date('07/29/2024')) {
          redlineWbaseline = -0.13
        } else if(new Date(contractSignDate) >= new Date('07/29/2024')) {
          redlineWbaseline = -0.16
        }

        let percentage = new Date(contractSignDate) >= new Date('01/01/2024') && new Date(contractSignDate) < new Date('03/11/2024') ? 0.25 : 0.1
        let prepaidPortion = (systemSizeKW * 1000) * percentage
        commissionAmount = commissionAmount + prepaidPortion

        output(`*** adjusting redline/baseline for 1Solar NV to: ${redlineWbaseline}`, B)
        output("****** prepaid portion that was added to the total commission amount *******", B)
        output(`Prepaid Portion: ${prepaidPortion}, Total Commission Amount with prepaid portion added to it: ${commissionAmount}`, B)
      } 

      if(new Date(contractSignDate) < new Date('07/29/2024') && installer === 'Freedom Forever') {
        redlineWbaseline = -0.18
        output(`*** adjusted redlineWbaseline to ${redlineWbaseline}`, B)
      } else if(new Date(contractSignDate) < new Date('07/29/2024') && installer !== 'Freedom Forever' && installer !== '1Solar') {
        redlineWbaseline = -0.195
        output(`*** adjusted redlineWbaseline to ${redlineWbaseline}`, B)
      }

      output("************** New TCR Formula **************************", B)
      output(`(commission amount: ${commissionAmount} + redline with baseline: (${redlineWbaseline}) * SystemSizeKW: ${Number(C)} * 1000)` , B)
      output(`- (0.05 * commission amount ${commissionAmount}) = tcr`, B)
    
      tcr = (commissionAmount + ((redlineWbaseline) * (Number(C) * 1000))) - (0.05 * commissionAmount)
    } else if(new Date(contractSignDate) < NEW_CALC_DATE && installer === 'Freedom Forever' && ppa !== 1) {
      redlineWbaseline = Number(A) > 0 ? epc_redline - Number(A) : 0
      tcr = (commissionAmount + ((redlineWbaseline * 0.8 ) * (Number(C) * 1000))) * (0.804375)

      output("*************** Old Freedom TCR Formula ******************", B)
      output(`if baseline > 0 then redlineWbaseline = epc_redline: ${epc_redline} - baseline: ${Number(A)}. otherwise relineWbaseline = 0`, B)
      output(`tcr = (commissionAmount: ${commissionAmount} + ((redlineWbaseline: ${redlineWbaseline} * 0.8) * (systemSize in Watts: ${Number(C) * 1000}))) - (0.05 * commissionAmount:${commissionAmount})`, B)
      output("******************************************************", B)
    } else if(new Date(contractSignDate) < NEW_CALC_DATE && installer !== 'Freedom Forever' && ppa !== 1) {
      tcr = commissionAmount * 0.79

      output("*************** Old NonFreedom TCR Formula ******************", B)
      output(`tcr = commissionAmount: ${commissionAmount} * 0.79`, B)
    }

    output(`tcr ${tcr} - deductions: ${Number(D)}`, B)
    output(`* Total percentages: (Reps, Leadership, Expenses) ${Number(percentagesTotal)}`, B)
    output("****************************", B)

    return (tcr - Number(D)) * Number(percentagesTotal) 
  }

  const sumOfSelectedLeadershipValues = () => {
    if (leadershipLevel.length == 0) {
      return 0
    } else {
      let selectedValues = leadershipLevel.map(x => {
        if (x.selected) {
          if (x.value) {
            return Number(x.value)
          } else {
            return 0
          }
        } else {
          return 0
        }
      })

      const initialValue = 0
      const finalSum = selectedValues.reduce((pV, cV) => pV + cV, initialValue)

      return finalSum
    }
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#333'
      },
      secondary: {
        main: blue[100]
      }
    }
  })

  const handleCloser = (closer) => {
    setCloser(closer)
  }

  const handleInstaller = async (market, installer, ppa, contractSignDate) => {
    await setInstaller(installer)
  }

  const handleContractSignDate = async (contractSignDate) => {
    console.log('hit handler for contract date', contractSignDate)
    setContractSignDate(contractSignDate)
    clearCalculations()
  }

  const handleLeadershipLevel = (leadershipLevel) => {
    setLeadershipLevel(leadershipLevel)
  }

  const handleMarket = async (market, EPCValues, ppa, marketValues) => {
    setMarket(market)

    let selectedMarketInstallerVals = marketValues.filter(x => x.market == market).map(x => x.installer).sort()
    let uniqueInstallerVals = [...new Set(selectedMarketInstallerVals)]

    setInstallerValues(uniqueInstallerVals)
  }

  const handleNetPricePerWatt = async (netPricePerWatt) => {
    await setNetPricePerWatt(netPricePerWatt)

    let start_nppw = Number(netPricePerWatt) - 0.25
    let end_nppw = Number(netPricePerWatt) + 0.25
    let netPricePerWattValues = []

    for (let i = start_nppw; i < end_nppw; i += 0.05) {
      netPricePerWattValues.push(i.toFixed(2))
    }

    setNetPricePerWattValues(netPricePerWattValues)
  }

  const handleSystemSizeKW = (systemSizeKW) => {
    setSystemSizeKW(systemSizeKW)
  }

  const handleLead = (lead) => {
    setLead(lead)
  }

  const handleDeductions = (deductions) => {
    setDeductions(deductions)
  }

  const handleEstimatedInstalls = (estimatedInstalls) => {
    setEstimatedInstalls(estimatedInstalls)
  }

  const handleTCRPercentage = (tcrPercentage) => {
    setTCRPercentage(tcrPercentage)
    const rt = Number(tcrPercentage) / 100

    handleRepTenure(rt)
  }

  const handleRepTenure = async (repTenure) => {
    setRepTenure(repTenure)
  }

  const handleRepTenureValues = (repTenureValues) => {
    setRepTenureValues(repTenureValues)
  }

  const handleppa = async (market, installer, ppa, installDate, loanAndCashMarketValues, sunRunMarketValues) => {
    setppa(ppa)
    setMarket('')
    setInstaller('')
  }

  const getFormData = (ppa) => {
    fetch(`/api/form_values/${ppa}/${contractSignDate}`)
      .then(res => res.json())
      .then(json => {
        setGSFormValues(json)
        let marketVals = json.marketValues.map(x => x.market)
        let uniqMarketVals = [...new Set(marketVals)]
        setLoanAndCashMarketValues(uniqMarketVals)
      })
  }

  const authenticate = (password) => {
    if(password) {
      fetch(`/api/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({password, contractSignDate})
      })
        .then(res => {
          if (res.status == 200) {
            setAuthenticated(true)

            let expireDate = new Date()
            expireDate.setDate(expireDate.getDate() + 7)
            setCookie('authenticate', true, {path: '/', expires: expireDate})
            setCookie('password', password, {path: '/', expires: expireDate})

          } else {
            logout()
            setErrorMessage('Failed to Authenticate')
          }
          return res.json()
        })
        .then(json => {
          const repTenureValues = json.buttons.filter(x => x.type === "Rep Tenure")
          const leadershipLevels = json.buttons.filter(x => x.type === "Leadership Level")
          const customElements = json.buttons.filter(x => x.type === "Custom Element")
          const userType = json.userType
          let expireDate = new Date()

          const build = leadershipLevels.map(x => {
            if(x.title == "Sales Rep") {
              return {...x, selected: true}
            } else {
              return {...x, selected: false}
            }
          })

          expireDate.setDate(expireDate.getDate() + 7)
          setCookie('userType', userType, {path: '/', expires: expireDate})

          setCustomElements(customElements)
          setRepTenureValues(repTenureValues)
          setDefaultRepTenure(repTenureValues, userType)
          handleLeadershipLevel(build)
          buildAdminRepTenureValues(repTenureValues)

          return json
        })
        .catch(error => error)
    } else {
      setErrorMessage('')
      logout()
    }
  }

  const buildAdminRepTenureValues = (repTenureValues) => {
    const buttons = repTenureValues.map(button => {
      return {title: button.title, value: button.value, type: button.type, selected: false}
    })

    setAdminRepTenureValues(buttons)

    return
  }
  
  const setDefaultRepTenure = (json, userType) => {
    if (cookies.userType == 'consultant') {
      let defaultRepTenure = json.filter(x => x.title == 'Consultant')[0].value
      setRepTenure(defaultRepTenure)
    } else if (cookies.userType == 'energy_pro') {
      let defaultRepTenure = json.filter(x => x.title == 'Energy Pro')[0].value
      setRepTenure(defaultRepTenure)
    } else if (cookies.userType == 'specialist') {
      let defaultRepTenure = json.filter(x => x.title == 'Specialist')[0].value
      setRepTenure(defaultRepTenure)
    } else if (json.length > 0 && !cookies.userType) {
      let defaultRepTenure = json[0].value
      setRepTenure(defaultRepTenure)
    } else {
      setRepTenure(0.5)
    }

    return
  }

  const createNewButtonVariables = (password, repButtons, leaderButtons) => {
    let buttons = []
    repButtons.forEach(x => {
      if(x.selected) {
        buttons.push(x)
      }
    })
    leaderButtons.forEach(x => {
      if(x.selected) {
        buttons.push(x)
      }
    })

    fetch(`/api/create_variable`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({password, buttons})
    }).then(res => {
      if(res.status == 200) {
        alert("Custom password created successfully")
        logout()
      } else {
        alert("Failed to create custom password")
      }
    })
  }

  const logout = () => {
    setAuthenticated(false)
    setErrorMessage('')
    setLeadershipLevel([])
    setRepTenureValues([])
    removeCookie('authenticate')
    removeCookie('userType')
    removeCookie('password')
    setCalculationsSubmitted(false)
  }

  useEffect(() => {
    if((repTenureValues.length < 1 && leadershipLevel < 1) || !cookies.authenticate) {
       authenticate(cookies.password) 
    }
    getFormData(ppa)

    handleNetPricePerWatt(netPricePerWatt)
  }, [])

  useEffect(() => {
    if(runCalculations) {
      buildTablePaymentCalculations()
    }
  }, [runCalculations, market, installer, repTenure, ppa, systemSizeKW, netPricePerWattValues, lead, deductions, contractSignDate, installer, leadershipLevel, closer, tcrVariables])

  useEffect(() => {
    getFormData(ppa)

  }, [contractSignDate, ppa])

  useEffect(() => {
    if (installer && market) {
      getVariables()
    }
  }, [contractSignDate, market, installer, ppa])

  return (
    <div className="flex-column flex-ai-center width70 flex-jc-center">
      <ThemeProvider theme={theme}>
        {!authenticated ? <AuthenticationModal authenticate={authenticate} authenticated={authenticated}
                                               errorMessage={errorMessage}/> : null}
        {authenticated && disclosureOpen && cookies.userType !== "admin" ? <DisclosureModal setDisclosureOpen={setDisclosureOpen} disclosureOpen={disclosureOpen}/> : null }
        <div id="background-image">
          {authenticated ?
            <LogoutIcon
            className="absolute top-20 right-35 color-white cursor-pointer"
            onClick = {() => logout()}
            /> : null}
        </div>
        <div id="vivint-energy-img"></div>
        { cookies.userType === "admin" ?
          <div id="calculator" className="width100 flex-column flex-ai-fs flex-jc-fs">
            <AdminPortal
              adminRepTenureValues={adminRepTenureValues}
              setAdminRepTenureValues={setAdminRepTenureValues}
              leadershipLevel={leadershipLevel}
              handleLeadershipLevel={handleLeadershipLevel}
              adminPassword={adminPassword}
              setAdminPassword={setAdminPassword}
              createNewButtonVariables={createNewButtonVariables}
            />
          </div>
        :
        <div id="calculator" className="width100 flex-column flex-ai-fs flex-jc-fs">
          <h3>Status Inputs</h3>
          <RepTenure
            handleRepTenureValues={handleRepTenureValues}
            repTenureValues={repTenureValues}
            repTenure={repTenure}
            handleRepTenure={handleRepTenure}
            userType={cookies.userType}
          />

          <LeaderShipLevel
            leadershipLevel={leadershipLevel}
            handleLeadershipLevel={handleLeadershipLevel}
          />

          {customElements.map((x,i) => ( 
            <div key={x.title + i + x.value} className="flex-column width100">
              <p>{x.title}</p>
              <div className="flex-row width100 flex-ai-fs">
              <TextField
                id="outlined-number-tcr"
                type="number"
                onChange={e => handleTCRPercentage(e.target.value)}
                value={tcrPercentage}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><p className="color-white">%</p></InputAdornment>
                }}
                sx={{width: '100%'}}
              />
              </div>
            </div>
           ))}

          <div className="flex-column width100">
            <Tooltip title="This is the type of contract you are selling to the customer.">
              <p>Product</p>
            </Tooltip>
            <div className="flex-row width100 flex-jc-sb">
              <Button className="width50 margin-right10" variant={!ppa ? 'contained' : 'outlined'}
                      onClick={() => handleppa(market, installer, false, contractSignDate, loanAndCashMarketValues, sunRunMarketValues)}>Loan
                and Cash</Button>
              <Button
                className="width50 color-white"
                variant={ppa ? 'contained' : 'outlined'}
                onClick={() => handleppa(
                  market, installer, true, contractSignDate, loanAndCashMarketValues, sunRunMarketValues
                )}>Power Purchase Agreement</Button>
            </div>
          </div>

          <div className="flex-column width100">
            <p>Contract Sign Date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                value={contractSignDate}
                onChange={(e) => {
                  handleContractSignDate(new Date(`${e.$M + 1}/${e.$D}/${e.$y}`));
                }}
                renderInput={(params) => <TextField {...params} />}
                style={{width: '100%'}}
              />
            </LocalizationProvider>
          </div>

          <div className="flex-column width100">
            <p>Market</p>
            <div className="flex-row width100 flex-ai-fs">
              <Box sx={{minWidth: '100%'}}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={market}
                    onChange={e => handleMarket(e.target.value, EPCValues, ppa, GSFormValues.marketValues)}
                    className="width100"
                  >
                    {loanAndCashMarketValues?.map((mv, i) => {
                      return (
                        <MenuItem key={mv + i} value={mv}>{mv}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>

          <LoanAndCash
            installer={installer}
            handleInstaller={handleInstaller}
            GSFormValues={GSFormValues}
            netPricePerWatt={netPricePerWatt}
            handleNetPricePerWatt={handleNetPricePerWatt}
            systemSizeKW={systemSizeKW}
            handleSystemSizeKW={handleSystemSizeKW}
            handleLead={handleLead}
            handleCloser={handleCloser}
            handleDeductions={handleDeductions}
            lead={lead}
            closer={closer}
            deductions={deductions}
            market={market}
            repTenureValues={repTenureValues}
            repTenure={repTenure}
            ppa={ppa}
            contractSignDate={contractSignDate}
            setContractSignDate={handleContractSignDate}
            installerBaseline={installerBaseline}
            netPricePerWattValues={netPricePerWattValues}
            tcrVariables={tcrVariables}
            buildTablePaymentCalculations={buildTablePaymentCalculations}
            paymentCalculationsToDisplay={paymentCalculationsToDisplay}
            handleEstimatedInstalls={handleEstimatedInstalls}
            estimatedInstalls={estimatedInstalls}
            selectedPayScale={selectedPayScale}
            installerValues={installerValues}
            epcRedline={epcRedline}
            setRunCalculations={setRunCalculations}
          />
        </div>
        }
      </ThemeProvider>
    </div>
  )
}

export default App
