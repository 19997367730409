import React from 'react'
import {
  TextField,
  InputAdornment,
} from '@mui/material'

export default function SystemSize(props){
  const {
    handleSystemSizeKW,
    systemSizeKW,
    contractSignDate,
    setContractSignDate
  } = props

  return(
    <div className='flex-column width100'>
      <p>System size (kW)</p>
      <div className='flex-row width100 flex-ai-fs'>
        <TextField
          id="outlined-number-ss"
          type="number"
          onChange={e => handleSystemSizeKW(e.target.value)}
          value={systemSizeKW}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 0.001,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end"><p className="color-white">kW</p></InputAdornment>
          }}
          sx={{width: '100%'}}
        />
      </div> 
    </div>
  )
}