import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function DisclosureModal({
  setDisclosureOpen,
  disclosureOpen
}){


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80% !important',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <React.Fragment>
      <Modal
        hideBackdrop
        open={disclosureOpen}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: '70%', height: '30%' }}>
           <FormControl
            sx={{ width: '98%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}
            variant="outlined">
            <h3 style={{fontSize: '1.17em' }} className="color-black">The following is an ESTIMATE of your pay based on the parameters selected. All results are subject to change based on the data received by the installer.</h3>
            <Button
              variant="contained"
              onClick={() => setDisclosureOpen(false)}
              className="width100"
              size='small'
              sx={{fontSize: '1.17em'}}
            >Acknowledge</Button>
          </FormControl>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

